import React from 'react'

import { Flex } from '@rebass/grid/emotion'
import { GridContainer } from '../../../../components/Grid'
import { ValueListContainer, ValueListItem } from '../../../../components/ValueList'
import QuestionBlock from '../../../../components/QuestionBlock'
import {
  SectionDashboardDescription,
  SectionDashboardQuestions,
  SectionDashboardContainer,
  SectionDashboardContentContainer,
} from '../../../../components/SectionDashboard'

import { Colors } from '../../../../utils/styles'

const providerAccountsColor = Colors.SECONDARY
const providerDashboardName = 'Account Profiles'
const providerDashboardDescription = `
  In-depth account profiles were developed for priority provider accounts,
  with profiling information structured across 5 key business questions.
`

const providerAccountsValues = (
  <ValueListContainer>
    <ValueListItem
      iconColor={providerAccountsColor}
      text="Understand key account access & prescribing drivers or barriers for performance"
    />
    <ValueListItem
      iconColor={providerAccountsColor}
      // eslint-disable-next-line
      text="Identify both traditional & emerging account engagement opportunities for action planning"
    />
    <ValueListItem
      iconColor={providerAccountsColor}
      text="Maintain single-source of intelligence on updates to M&A, business model & pathways"
    />
  </ValueListContainer>
)

const providerAccountQuestions = (
  <Flex flexWrap="wrap">
    <QuestionBlock
      title="Account Overview"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565365393/pulse-analytics-marketing/icons/provider-accounts-account-overview.svg"
      // eslint-disable-next-line
      question="How is my product performing & what account characteristics may explain utilization?"
      color={providerAccountsColor}
    />
    <QuestionBlock
      title="Business Model & Operations"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565365393/pulse-analytics-marketing/icons/provider-accounts-business-model.svg"
      question="What are the account’s business model priorities, accreditations, and staffing?  "
      color={providerAccountsColor}
    />
    <QuestionBlock
      title="Clinical Sophistication"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565365393/pulse-analytics-marketing/icons/provider-accounts-clinical-sophistication.svg"
      // eslint-disable-next-line
      question="What is the account’s utilization patterns, prescribing drivers, and testing protocols?"
      color={providerAccountsColor}
    />
    <QuestionBlock
      title="Protocols & Pathways"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565365393/pulse-analytics-marketing/icons/provider-accounts-protocols-pathways.svg"
      question="How do internal protocols and external pathways influence access / prescribing? "
      color={providerAccountsColor}
    />
    <QuestionBlock
      title="Manufacturer Engagement"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565365393/pulse-analytics-marketing/icons/provider-accounts-manufacturer-engagement.svg"
      question="What engagement restrictions exist, and which stakeholders should be prioritized?"
      color={providerAccountsColor}
    />
  </Flex>
)

const ProviderAccounts = () => (
  <SectionDashboardContainer>
    <SectionDashboardContentContainer color={providerAccountsColor}>
      <GridContainer flexWrap="wrap" style={{ padding: 0 }}>
        <SectionDashboardDescription
          title={providerDashboardName}
          description={providerDashboardDescription}
          values={providerAccountsValues}
        />
        <SectionDashboardQuestions
          color={providerAccountsColor}
          questions={providerAccountQuestions}
          subtitle={providerDashboardName}
        />
      </GridContainer>
    </SectionDashboardContentContainer>
  </SectionDashboardContainer>
)

export default ProviderAccounts
