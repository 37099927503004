import React from 'react'

import SEO from '../../components/seo'

import LayoutProvider from '../../pageLayouts/LayoutProducts/LayoutProvider'

const ProviderKeyAccountsPage = () => (
  <div>
    <SEO
      title="Data Analytics and Decision Support Platform for Market Access"
      keywords={[
        'pulse digital',
        'healthcare',
        'react',
        'software engineering',
        'new york',
        'nyc',
        'pulse',
        'javascript',
        'analytics',
        'development',
        'providers',
        'provider',
        'hospitals',
        'community practices',
        'oncology',
        'specialty',
        'dashboard',
        'key account',
        'key account management',
        'field team',
        'tracking',
      ]}
    />
    <LayoutProvider />
  </div>
)

export default ProviderKeyAccountsPage
