import React from 'react'

import { Flex } from '@rebass/grid/emotion'
import { GridContainer } from '../../../../components/Grid'
import { ValueListContainer, ValueListItem } from '../../../../components/ValueList'
import QuestionBlock from '../../../../components/QuestionBlock'
import {
  SectionDashboardDescription,
  SectionDashboardQuestions,
  SectionDashboardContainer,
  SectionDashboardContentContainer,
} from '../../../../components/SectionDashboard'

import { Colors } from '../../../../utils/styles'

const providerManagementColor = Colors.PRIMARY
const payerDashboardName = 'Management Dashboard'
const payerDashboardDescription = `
  The Providers Key Accounts Tool provides a Management view to show
  high-level account segmentation based on internal operations and prescribing behaviors.
`

const payerManagementValues = (
  <ValueListContainer>
    <ValueListItem
      iconColor={providerManagementColor}
      // eslint-disable-next-line
      text="Inform prioritization of clinical & non-clinical value drivers impacting value perception and utilization"
    />
    <ValueListItem
      iconColor={providerManagementColor}
      // eslint-disable-next-line
      text="Support alignment of key opportunities and engagement objectives across home-office and field-based teams"
    />
    <ValueListItem
      iconColor={providerManagementColor}
      text="Share potential engagement planning activities for consideration"
    />
  </ValueListContainer>
)

const payerManagementQuestions = (
  <Flex flexWrap="wrap">
    <QuestionBlock
      title="Product Performance"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565365245/pulse-analytics-marketing/icons/provider-mgmt-product-performance.svg"
      question="How is my product performing relative to competitors at my key accounts?"
      color={providerManagementColor}
    />
    <QuestionBlock
      title="Regional Alignment"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565365245/pulse-analytics-marketing/icons/provider-mgmt-regional-alignment.svg"
      // eslint-disable-next-line
      question="In which regions do I have a greater concentration of high vs. low-performing accounts?"
      color={providerManagementColor}
    />
    <QuestionBlock
      title="Access & Prescribing Dynamics"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565365245/pulse-analytics-marketing/icons/provider-mgmt-access-prescribing-dynamics.svg"
      // eslint-disable-next-line
      question="Within each local market, what key account & environmental dynamics may be influencing utilization / value perception?"
      color={providerManagementColor}
    />
    <QuestionBlock
      title="Opportunity Prioritization"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565365245/pulse-analytics-marketing/icons/provider-mgmt-opportunity-prioritization.svg"
      question="What account opportunities should we prioritize?"
      color={providerManagementColor}
    />
    <QuestionBlock
      title="Engagement Planning"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565365245/pulse-analytics-marketing/icons/provider-mgmt-engagement-planning.svg"
      question="What segment-specific engagement considerations may inform action planning?"
      color={providerManagementColor}
    />
  </Flex>
)

const ProviderManagement = () => (
  <SectionDashboardContainer>
    <SectionDashboardContentContainer color={providerManagementColor}>
      <GridContainer flexWrap="wrap" style={{ padding: 0 }}>
        <SectionDashboardDescription
          title={payerDashboardName}
          description={payerDashboardDescription}
          values={payerManagementValues}
        />
        <SectionDashboardQuestions
          color={providerManagementColor}
          questions={payerManagementQuestions}
          subtitle={payerDashboardName}
        />
      </GridContainer>
    </SectionDashboardContentContainer>
  </SectionDashboardContainer>
)

export default ProviderManagement
