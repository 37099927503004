import React from 'react'

import Layout from '../../../components/Layout'
import RequestDemoCtaBlock from '../../../components/RequestDemoCtaBlock'

import ProviderHero from './ProviderHero'
import ProviderManagement from './ProviderManagement'
import ProviderAccounts from './ProviderAccounts'

const LayoutProvider = () => (
  <Layout>
    <ProviderHero />
    <ProviderManagement />
    <ProviderAccounts />
    <RequestDemoCtaBlock />
  </Layout>
)

export default LayoutProvider
