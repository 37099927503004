import React from 'react'

import ProductHero from '../../../../components/ProductHero'

import toolContent from '../../../../content/toolContent'
import assets from '../../../../content/assets'

const ProviderHero = () => (
  <div>
    <ProductHero
      backgroundImage={assets.images.fluidBubblesPrimary5}
      image={toolContent.heroToolImage.provider}
      imageAltText="Provider Key Accounts Tool Image"
      icon={toolContent.iconWhite.provider}
      title={toolContent.title.provider}
      description={toolContent.description.provider}
    />
  </div>
)

export default ProviderHero
